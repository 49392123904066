import React, { ReactNode, createContext, useContext, useState } from 'react';
import useForm, { FormHook } from './Components/Hooks/useForm';

export interface DocsProps {
  url: string;
  tipo: number;
}

interface NovoMedicoForm {
  nom_clinica: string;
  num_cnpj: string;
  des_endereco: string;
  bairro: string;
  num_cep: string;
  num_inscricao: string;
  des_email: string;
  nom_fantasia: string;
  telefones: string;
  especialidades: string;
  num_reg_conselho: string;
  cidade: string;
  uf: string;
  email_clinica: string;
  whatsapp: string;
  insta_clinica: string;
  rt_clinica: string;
  cpf_rt: string;
  num_conselho_rt: string;
  email_rt: string;
  telefone_rt: string;
  nome_fat: string;
  telefone_fat: string;
  email_fat: string;
  docs?: DocsProps[] | undefined;
}
interface MedicoContextProps {
  nome: FormHook;
  dataNasc: FormHook;
  conselho: FormHook;
  numConselho: FormHook;
  especialidades: FormHook;
  rg: FormHook;
  emissor: FormHook;
  cpf: FormHook;
  naturalidade: FormHook;
  uf: FormHook;
  uf_conselho: FormHook;
  uf_endereco: FormHook;
  sexo: FormHook;
  estadoCivil: FormHook;
  endereco: FormHook;
  cidade: FormHook;
  cep: FormHook;
  telefoneFixo: FormHook;
  celular: FormHook;
  email: FormHook;
  whatsapp: FormHook;
  instagram: FormHook;
  resp_cadastro: FormHook;
  tel_resp_cadastro: FormHook;
  dadosMed: NovoMedicoForm | {};
  formData: MedicoFormTypes | undefined;
  setFormData: React.Dispatch<
    React.SetStateAction<MedicoFormTypes | undefined>
  >;
  setDadosMed: React.Dispatch<React.SetStateAction<NovoMedicoForm | {}>>;
  medicos: any | undefined;
  setMedicos: React.Dispatch<React.SetStateAction<any | undefined>>;
  medico: any | undefined;
  setMedico: React.Dispatch<React.SetStateAction<any | undefined>>;
  clinicas: any | undefined;
  setClinicas: React.Dispatch<React.SetStateAction<any | undefined>>;
  clinicasPre: any | undefined;
  setClinicasPre: React.Dispatch<React.SetStateAction<any | undefined>>;
  clinica: any | undefined;
  setClinica: React.Dispatch<React.SetStateAction<any | undefined>>;
}

export interface MedicoFormTypes {
  nom_medico: string;
  dat_nascimento: string;
  num_cpf: string;
  sgl_conselho: string;
  num_crm: string;
  especialidades: any;
  num_identidade: string;
  nom_orgao_expedidor: string;
  naturalidade: string;
  des_uf: string;
  uf_conselho: string;
  uf_endereco: string;
  des_sexo_medico: string;
  des_endereco: string;
  des_estado_civil: string;
  cidade: string;
  num_cep: string;
  tel_fixo: string;
  tel_celular: string;
  des_email: string;
  whatsapp: string;
  instagram: string;
  resp_cadastro: string;
  tel_resp_cadastro: string;
}

const MedicoContext = createContext<MedicoContextProps | undefined>(undefined);

export const MedicoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const nome = useForm('');
  const dataNasc = useForm('data');
  const conselho = useForm('');
  const numConselho = useForm('number');
  const especialidades = useForm('');
  const rg = useForm('');
  const emissor = useForm('');
  const cpf = useForm('cpf');
  const naturalidade = useForm('');
  const uf = useForm('');
  const uf_conselho = useForm('');
  const uf_endereco = useForm('');
  const sexo = useForm('');
  const estadoCivil = useForm('');
  const endereco = useForm('');
  const cidade = useForm('');
  const cep = useForm('number');
  const telefoneFixo = useForm('telefone');
  const celular = useForm('telefone');
  const email = useForm('email');
  const whatsapp = useForm('telefone');
  const instagram = useForm('');
  const resp_cadastro = useForm('');
  const tel_resp_cadastro = useForm('');
  const [medicos, setMedicos] = useState<any | undefined>(undefined);
  const [medico, setMedico] = useState<any | undefined>(undefined);
  const [clinicas, setClinicas] = useState<any | undefined>(undefined);
  const [clinicasPre, setClinicasPre] = useState<any | undefined>(undefined);
  const [clinica, setClinica] = useState<any | undefined>(undefined);
  const [formData, setFormData] = useState<MedicoFormTypes>();
  const [dadosMed, setDadosMed] = useState<NovoMedicoForm | {}>({
    nom_clinica: '',
    num_cnpj: '',
    des_endereco: '',
    bairro: '',
    num_cep: '',
    num_inscricao: '',
    des_email: '',
    nom_fantasia: '',
    telefones: '',
    especialidades: '',
    num_reg_conselho: '',
    cidade: '',
    uf: '',
    email_clinica: '',
    whatsapp: '',
    insta_clinica: '',
    rt_clinica: '',
    cpf_rt: '',
    num_conselho_rt: '',
    email_rt: '',
    telefone_rt: '',
    nome_fat: '',
    telefone_fat: '',
    email_fat: '',
    resp_cadastro: '',
    tel_resp_cadastro: '',
    uf_conselho: '',
    uf_endereco: '',
  });

  return (
    <MedicoContext.Provider
      value={{
        resp_cadastro,
        tel_resp_cadastro,
        formData,
        setFormData,
        clinicasPre,
        setClinicasPre,
        clinica,
        clinicas,
        setClinica,
        setClinicas,
        medicos,
        setMedicos,
        medico,
        setMedico,
        dadosMed,
        setDadosMed,
        nome,
        dataNasc,
        conselho,
        numConselho,
        especialidades,
        rg,
        emissor,
        cpf,
        naturalidade,
        uf,
        sexo,
        estadoCivil,
        endereco,
        cidade,
        cep,
        telefoneFixo,
        celular,
        email,
        whatsapp,
        instagram,
        uf_conselho,
        uf_endereco,
      }}
    >
      {children}
    </MedicoContext.Provider>
  );
};

// Crie um hook para consumir o contexto
export const useMedicoContext = (): MedicoContextProps => {
  const context = useContext(MedicoContext);

  if (!context) {
    throw new Error(
      'useAssociadoContext deve ser utilizado dentro de um AssociadoProvider',
    );
  }

  return context;
};
