import React, { useEffect, useMemo, useState } from 'react';
import styles from './Associados.module.css';
import Head from '../Helper/Head';
import TituloAssociado from '../Helper/TituloAssociado';
import { Link } from 'react-router-dom';
import { useMedicoContext } from '../../useMedicoContext';
import { useUserContext } from '../../UserContext';
import { GET_ASSOCIADOS, GET_PROFISSIONAIS } from '../../api';
import { useAssociadoContext } from '../../useAssociadoContext';

const Profissionais = () => {
  const { setMedico, medicos = [], setMedicos } = useMedicoContext();
  const { loading, setLoading, tokenGer } = useUserContext();
  const { setFormData } = useMedicoContext();
  const [pesquisar, setPesquisar] = React.useState('');
  const [pesquisando, setPesquisando] = React.useState(false);
  const [pesquisados, setPesquisados] = React.useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: '',
    direction: 'ascending',
  });

  function definirMedico(id: any): React.MouseEventHandler<HTMLAnchorElement> {
    return (event): void => {
      setMedico(medicos.filter((item: any) => item.id_medico === id));
    };
  }

  function handleChange({ target }: any) {
    setPesquisar(target.value.toUpperCase());
  }

  useEffect(() => {
    if (pesquisar && pesquisar.length > 2) {
      setPesquisando(true);
      setPesquisados(
        medicos.filter((medico: any) => {
          if (
            medico.nom_medico.toUpperCase().includes(pesquisar) ||
            medico.num_crm.toUpperCase().includes(pesquisar)
          )
            return medico;
        }),
      );
      // setPesquisados(pesquisados)
    } else {
      setPesquisando(false);
      setPesquisados([]);
    }
  }, [pesquisar]);

  useEffect(() => {
    async function buscarMedicos() {
      try {
        setLoading(true);
        const { url, options } = GET_PROFISSIONAIS(tokenGer);
        const response = await fetch(url, options);
        const data = await response.json();
        if (!response.ok) throw new Error(data.message);
        setMedicos(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
    setMedico([]);
    buscarMedicos();
    setFormData(undefined);
  }, []);

  const sortedMedicos = useMemo(() => {
    return [...medicos].sort((a, b) => {
      if (sortConfig.key) {
        let aValue = a[sortConfig.key] || '';
        let bValue = b[sortConfig.key] || '';
        if (sortConfig.key === 'nom_medico') {
          aValue = aValue.toUpperCase();
          bValue = bValue.toUpperCase();
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      }
      return 0;
    });
  }, [medicos, sortConfig]);

  function requestSort(key: string) {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  }

  return (
    <section className={`${styles.containerMain} animeLeft`}>
      <Head
        title="Área Gerencial - Profissionais"
        description="Área gerencial"
      />
      <TituloAssociado titulo="Profissionais" />
      <input
        className={styles.procurar}
        type="text"
        placeholder="Pesquisar clínica"
        onChange={handleChange}
        value={pesquisar}
      />
      <div className={styles.divCorpoClinico}>
        <table className={styles.table}>
          <tr>
            <th onClick={() => requestSort('nom_medico')}>Nome</th>
            <th onClick={() => requestSort('num_crm')}>Nº Conselho</th>
            <th onClick={() => requestSort('ind_status')}>Status</th>
          </tr>

          {!pesquisando
            ? sortedMedicos &&
              sortedMedicos.map((item: any, index: any) => (
                <>
                  <Link
                    key={index}
                    to={item.num_crm}
                    onClick={definirMedico(item.id_medico)}
                  >
                    <tr>
                      <td>{item.nom_medico}</td>
                      <td>{item.num_crm}</td>
                      <td>{item.ind_status}</td>
                    </tr>
                  </Link>
                  <span className={styles.sep}></span>
                </>
              ))
            : pesquisados &&
              pesquisados.map((item: any, index: any) => (
                <>
                  <Link
                    key={index}
                    to={item.num_crm}
                    onClick={definirMedico(item.id_medico)}
                  >
                    <tr>
                      <td>{item.nom_medico}</td>
                      <td>{item.num_crm}</td>
                      <td>{item.ind_status}</td>
                    </tr>
                  </Link>
                  <span className={styles.sep}></span>
                </>
              ))}
        </table>
      </div>
    </section>
  );
};

export default Profissionais;
