import React, { useEffect } from 'react';
import styles from './VideosHome.module.css';
import { useFileContext } from '../../useFileContext';
import { useLocation, useNavigate } from 'react-router-dom';
import TituloAssociado from '../Helper/TituloAssociado';
import Button from '../Forms/Button';

const VideosHome = () => {
  const url = `https://www.youtube.com/embed/YZ84iQrbYjw?autoplay=1&muted=1`;
  const { setCategoria } = useFileContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setCategoria([]);
  }, []);

  return (
    <section className={styles.containerPrincipal}>
      <div className={styles.divTitulo}>
        <TituloAssociado titulo="Tutoriais na Prática" />
        {pathname.includes('area-gerencial') && (
          <Button
            onClick={() => navigate('novo-video')}
            descricao="+ novo video"
            classe="third"
          />
        )}
      </div>
      <div className={styles.videoDesc}>
        <iframe
          className={styles.video}
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube video player"
        ></iframe>
        <p></p>
      </div>
    </section>
  );
};

export default VideosHome;
