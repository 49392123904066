import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from './Convenio.module.css';
import { useAssociadoContext } from '../../useAssociadoContext';
import Head from '../Helper/Head';
import TituloAssociado from '../Helper/TituloAssociado';
import { useFileContext } from '../../useFileContext';
import { useUserContext } from '../../UserContext';
import { GET_GUIAS_CONVENIO } from '../../api';

const Convenio = () => {
  const { id } = useParams();
  const { setLoading, loading } = useUserContext();
  const { convenios, convenio, setConvenio } = useAssociadoContext();
  const { guias, setGuias } = useFileContext();

  useEffect(() => {
    async function getGuias() {
      try {
        setLoading(true);
        const { url, options } = GET_GUIAS_CONVENIO({
          id_convenio: convenio[0].id,
        });
        const response = await fetch(url, options);
        const json = await response.json();
        if (!response.ok) throw new Error(json.message);
        setGuias(json);
      } catch (error: any) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    }
    getGuias();
  }, [convenio]);

  useEffect(() => {
    function definirConvenio() {
      const convenioAtual = convenios?.filter(
        (item: any) => item.id === Number(id),
      );
      setConvenio(convenioAtual);
    }
    definirConvenio();
  }, []);

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Convenios Geral"
        description="Área do associado"
      />
      {convenio && convenio[0] && (
        <>
          <TituloAssociado
            titulo={convenio[0]?.nome}
            usuario={
              convenio[0] && convenio[0].usuario ? convenio[0].usuario : ''
            }
            senha={convenio[0] && convenio[0].senha ? convenio[0].senha : ''}
            tipoAut={
              convenio[0] && convenio[0].tipo_aut ? convenio[0].tipo_aut : ''
            }
            dataCred={
              convenio[0].data_alt_credencial
                ? convenio[0].data_alt_credencial
                : ''
            }
            horaCred={
              convenio[0].hora_alt_credencial
                ? convenio[0].hora_alt_credencial
                : ''
            }
          />
        </>
      )}
      {!loading && convenio && convenio[0] && (
        <div className={styles.divConvenio}>
          {convenio && convenio[0] && convenio[0].url_aut ? (
            <a
              href={
                convenio[0].url_aut.includes('http')
                  ? convenio[0].url_aut
                  : 'https://' + convenio[0].url_aut
              }
              target="_blank"
            >
              Link para obter autorização do convênio
            </a>
          ) : convenio && convenio[0] && convenio[0].instrucao_aut ? (
            <p className={styles.alerta}>{convenio[0].instrucao_aut}</p>
          ) : (
            <p className={styles.alerta}>
              Paciente leva a guia autorizada pelo convênio.
            </p>
          )}
          {convenio && convenio[0] && (
            <>
              <Link to="circulares">circulares - {convenio[0].nome}</Link>
            </>
          )}
          {convenio && convenio[0] && (
            <>
              <Link to="informativos">informativos</Link>
            </>
          )}
          {convenio && guias.length > 0 && <Link to="guias">guias</Link>}
        </div>
      )}
    </section>
  );
};

export default Convenio;
