import React from 'react';
import styles from './Parceiros.module.css';
import { useInView } from 'react-intersection-observer';
import zeus from '../../Assets/Zeus.png';
import sicoob from '../../Assets/Sicoob.png';
import qualicorp from '../../Assets/QUALICORP.png';
import unicred from '../../Assets/UNICRED.png';
import { Link } from 'react-router-dom';

const Parceiros = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <section
      className={`${styles.containerPrincipal} ${inView ? 'animeLeft' : ''}`}
      ref={ref}
    >
      <h1>Nossas Parcerias</h1>
      <div className={styles.containerParceiros}>
        <Link to="https://www.unicred.com.br/evolucao" target="_blank">
          <div className={styles.divParceiro}>
            {/* <p>Unicred</p> */}
            <img src={unicred} alt="logomarca da unicred" />
          </div>
        </Link>
        <Link to="https://www.sicoob.com.br/" target="_blank">
          <div className={styles.divParceiro}>
            {/* <p>Sicoob</p> */}
            <img src={sicoob} alt="logomarca do sicoob" />
          </div>
        </Link>
        <Link to="https://zeusmkt.com/" target="_blank">
          <div className={styles.divParceiro}>
            {/* <p>Zeus Marketing</p> */}
            <img src={zeus} alt="logomarca da zeus marketing" />
          </div>
        </Link>
        <Link
          to="https://compre.qualicorp.com.br/?utm_source=amai_df&utm_medium=banner_entidade&utm_campaign=entidade_2022&utm_id=entidade"
          target="_blank"
        >
          <div className={styles.divParceiro}>
            <img src={qualicorp} alt="logomarca da qualicorp" />
          </div>
        </Link>
      </div>
    </section>
  );
};

export default Parceiros;
