import React, { createContext, useContext, useState, ReactNode } from 'react';

export interface VideoFormTypes {
  id?: number | string;
  url?: string;
  categoria_id?: number | string | undefined;
  convenio_id?: number | string | undefined;
  status?: boolean;
  descricao?: string;
  titulo?: string;
}

export interface Medico {
  nome: string;
  sgl_crm: string;
  num_crm: string;
  especialidade_padrao: string;
  clinicas?: Clinica[];
}

export interface Clinica {
  nom_clinica: string;
  nom_fantasia: string;
  id_clinica: number;
  endereco: string;
  ddd: string;
  telefone: string;
  medicos?: Medico[];
}
export interface DocsProps {
  url: string;
  tipo: number;
}

export interface Categoria {
  id: number;
  descricao: string;
  status: boolean;
}

export interface Video {
  id?: number;
  categoria_id?: number | string;
  convenio_id?: number | string;
  url?: string;
  descricao?: string;
  titulo?: string;
}
interface FileContextProps {
  uploadedFiles: File[] | undefined;
  setUploadedFiles: React.Dispatch<React.SetStateAction<File[] | undefined>>;
  uploadedPrev: File[] | undefined;
  setUploadedPrev: React.Dispatch<React.SetStateAction<File[] | undefined>>;
  busca: string | undefined;
  especialidadesGer: [];
  setEspecialidadesGer: React.Dispatch<React.SetStateAction<[]>>;
  categoria: Categoria[] | undefined;
  setCategoria: React.Dispatch<React.SetStateAction<Categoria[] | undefined>>;
  videos: Video[] | undefined;
  setVideos: React.Dispatch<React.SetStateAction<Video[] | undefined>>;
  video: Video | undefined;
  setVideo: React.Dispatch<React.SetStateAction<Video | undefined>>;
  categorias: Categoria[] | undefined;
  setCategorias: React.Dispatch<React.SetStateAction<Categoria[] | undefined>>;
  especialidade: any | undefined;
  setEspecialidade: React.Dispatch<React.SetStateAction<any | undefined>>;
  docsGer: [];
  setDocsGer: React.Dispatch<React.SetStateAction<[]>>;
  guias: [];
  formData: VideoFormTypes | undefined;
  setFormData: React.Dispatch<React.SetStateAction<VideoFormTypes | undefined>>;
  setGuias: React.Dispatch<React.SetStateAction<[]>>;
  setBusca: React.Dispatch<React.SetStateAction<string | undefined>>;
  resultado: (Medico | Clinica)[] | undefined | [];
  setResultado: React.Dispatch<
    React.SetStateAction<(Medico | Clinica)[] | undefined | []>
  >;
  resultadoFiltrado: (Medico | Clinica)[] | undefined | [];
  setResultadoFiltrado: React.Dispatch<
    React.SetStateAction<(Medico | Clinica)[] | undefined | []>
  >;
  medicos: Medico[] | undefined;
  setMedicos: React.Dispatch<React.SetStateAction<Medico[] | undefined>>;
  clinicas: Clinica[] | undefined;
  setClinicas: React.Dispatch<React.SetStateAction<Clinica[] | undefined>>;
  buscou: boolean;
  setBuscou: React.Dispatch<React.SetStateAction<boolean>>;
  token: string | undefined;
  setToken: React.Dispatch<React.SetStateAction<string | undefined>>;
  docs: DocsProps[] | undefined;
  setDocs: React.Dispatch<React.SetStateAction<DocsProps[] | undefined>>;
}

const FileContext = createContext<FileContextProps | undefined>(undefined);

export const FileProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[] | undefined>([]);
  const [uploadedPrev, setUploadedPrev] = useState<File[] | undefined>([]);
  const [busca, setBusca] = useState<string | undefined>('');
  const [resultado, setResultado] = useState<
    (Medico | Clinica)[] | undefined | []
  >(undefined);
  const [medicos, setMedicos] = useState<Medico[] | undefined>(undefined);
  const [clinicas, setClinicas] = useState<Clinica[] | undefined>(undefined);
  const [resultadoFiltrado, setResultadoFiltrado] = useState<
    (Medico | Clinica)[] | undefined
  >([]);
  const [buscou, setBuscou] = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [docs, setDocs] = useState<DocsProps[] | undefined>([]);
  const [especialidadesGer, setEspecialidadesGer] = useState<[]>([]);
  const [especialidade, setEspecialidade] = useState<any | undefined>();
  const [docsGer, setDocsGer] = useState<[]>([]);
  const [guias, setGuias] = useState<[]>([]);
  const [categoria, setCategoria] = useState<Categoria[] | undefined>();
  const [categorias, setCategorias] = useState<Categoria[] | undefined>();
  const [videos, setVideos] = useState<Video[]>();
  const [formData, setFormData] = useState<VideoFormTypes>();
  const [video, setVideo] = useState<Video>();

  return (
    <FileContext.Provider
      value={{
        video,
        setVideo,
        formData,
        setFormData,
        videos,
        setVideos,
        categoria,
        setCategoria,
        categorias,
        setCategorias,
        guias,
        setGuias,
        uploadedPrev,
        setUploadedPrev,
        especialidade,
        setEspecialidade,
        docsGer,
        setDocsGer,
        especialidadesGer,
        setEspecialidadesGer,
        docs,
        setDocs,
        uploadedFiles,
        setUploadedFiles,
        busca,
        setBusca,
        resultado,
        setResultado,
        medicos,
        setMedicos,
        clinicas,
        setClinicas,
        buscou,
        setBuscou,
        resultadoFiltrado,
        setResultadoFiltrado,
        token,
        setToken,
      }}
    >
      {children}
    </FileContext.Provider>
  );
};

export const useFileContext = (): FileContextProps => {
  const context = useContext(FileContext);

  if (!context) {
    throw new Error('useFileContext must be used within a FileProvider');
  }

  return context;
};
