import Select, { MultiValue, StylesConfig } from 'react-select';
import { GET_ESP_OPTIONS } from '../../api';
import { useEffect, useState } from 'react';
import {
  Especialidade,
  Option,
  useAssociadoContext,
} from '../../useAssociadoContext';
import styles from './SelectEsp.module.css';

const SelectEsp = (/* { espClinica }: { espClinica?: string[] } */) => {
  const { selectedOptions, setSelectedOptions, setEspecialidades } =
    useAssociadoContext();

  const [options, setOptions] = useState<Option[]>([]);
  async function getEspecialidades() {
    try {
      const { url, options } = GET_ESP_OPTIONS();
      const response = await fetch(url, options);
      if (!response.ok) throw new Error('Erro ao trazer especialidades');
      const json = await response.json();
      const espTrat = json.map((item: any) => {
        return {
          value: item.id_especialidade,
          label: item.des_especialidade,
        };
      });
      setSelectedOptions([]);
      setEspecialidades(json);
      setOptions(espTrat);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    getEspecialidades();
  }, []);

  const customStyles: StylesConfig<Option, true> = {
    control: (base) => ({
      ...base,
      background: 'var(--c-15)',
      border: '1px solid #ededed',
      borderRadius: '4px',
      padding: '7px 0',
      boxSizing: 'border-box',
      fontFamily: 'var(--type-second)',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#707070',
      transition: '0.2s',
      width: '100%',
      marginBottom: '20px',

      '&:hover, &:focus': {
        outline: 'none',
        borderColor: '#0b5690',
        background: 'white',
        boxShadow: '0px 0px 0px 3px rgba(11, 86, 144, 0.3)',
      },
    }),
    menu: (base) => ({
      ...base,
      margin: '-12px 0 0 0',
      zIndex: 5,
    }),
  };

  const handleChange = (newValue: MultiValue<Option>) => {
    setSelectedOptions(newValue);
    setEspecialidades(newValue as unknown as Especialidade[]);
  };

  return (
    <>
      <label className={styles.label} htmlFor="especialidades">
        Especialidades
      </label>
      <Select
        id="especialidades"
        className="spanAll"
        options={options} // Lista de opções
        isMulti // Permite múltiplas seleções
        value={selectedOptions} // Opções selecionadas
        onChange={handleChange} // Evento ao selecionar
        placeholder="Pesquise ou selecione..."
        noOptionsMessage={() => 'Nenhuma opção encontrada'}
        styles={customStyles} // Mensagem para busca sem resultados
      />
    </>
  );
};

export default SelectEsp;
