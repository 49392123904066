import React, { useEffect, useState } from 'react';
import styles from './BuscaProf.module.css';
import Input from '../Forms/Input';
import useForm from '../Hooks/useForm';
import { useFileContext } from '../../useFileContext';
import { Medico, Clinica } from '../../useFileContext';

// interface Medico{
//   nome: string;
//   sgl_conselho: string;
//   num_crm: string;
//   especialidade_padrao: string;
//   clinicas?: Clinica[]
// }

// interface Clinica{
//   nome: string;
//   endereco: string;
//   telefone: string;
//   medicos?: Medico[]
// }

const BuscaProf = () => {
  const {
    busca,
    setBusca,
    medicos,
    setMedicos,
    setResultado,
    resultado,
    setClinicas,
    clinicas,
    buscou,
    setBuscou,
    setResultadoFiltrado,
    resultadoFiltrado,
  } = useFileContext();

  function handleChange({ target }: any) {
    setBusca(target.value.toUpperCase());
  }

  useEffect(() => {
    let termos = busca?.split(' ');
    filtar(resultado, termos);
  }, [buscou]);

  useEffect(() => {
    let onlyClinicas: Clinica[] = (resultadoFiltrado || []).filter(
      (item: any): item is Clinica => {
        return item.hasOwnProperty('medicos');
      },
    );
    let onlyMedicos: Medico[] = (resultadoFiltrado || []).filter(
      (item: any): item is Medico => {
        return item.hasOwnProperty('clinicas');
      },
    );
    setMedicos(onlyMedicos);
    setClinicas(onlyClinicas);
  }, [resultadoFiltrado]);

  useEffect(() => {
    // if(busca === undefined || busca === ''){
    setBuscou(false);
  }, [busca]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetch('https://amaionline.com.br:2053/pesquisa');
        // const data = await fetch('http://localhost:1337/pesquisa');
        const json = await data.json();
        setResultado(json);
      } catch (e: any) {
        console.log('Erro no fetch de pesquisa: ', e.message);
      }
    };
    fetchData();
  }, []);

  function handleKeyPress(e: any) {
    if (e.key === 'Enter') {
      // Se a tecla pressionada for "Enter", chame a função handleButtonClick
      handleBusca();
    }
  }
  function handleBusca() {
    if (!buscou) {
      setBuscou(true);
    }
  }

  function removerAcentos(texto: string): string {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
  function filtar(resultados: (Medico | Clinica)[] | undefined, termos: any) {
    if (!resultados) return;
    if (termos.length !== 0 && buscou === true) {
      let resultadosFiltrados = resultados;
      termos.forEach((item: any) => {
        const termoNormalizado = removerAcentos(item.toLowerCase());
        resultadosFiltrados = resultadosFiltrados?.filter((objeto) => {
          return Object.values(objeto).some(
            (val) =>
              // typeof val === 'string' && val.toLowerCase().includes(item.toLowerCase())
              typeof val === 'string' &&
              contemPalavraExata(
                removerAcentos(val.replace(/\;/g, ' ').toLowerCase()),
                termoNormalizado,
              ),
          );
        });
      });
      setResultadoFiltrado(resultadosFiltrados);
    }
  }

  function contemPalavraExata(frase: string, palavra: string): boolean {
    const palavrasNaFrase = frase.split(/\s+/);
    return palavrasNaFrase.includes(palavra);
  }

  return (
    <div className={`${styles.divPrincipal} animeLeft`}>
      <h1>Encontre um profissional</h1>
      <div className={styles.divInput}>
        <input
          className={styles.procurar}
          type="text"
          placeholder="Pesquisar médico ou clínica"
          onChange={handleChange}
          value={busca}
          onKeyDown={handleKeyPress}
        />
        <button className={styles.buttonBusca} onClick={handleBusca}></button>
      </div>
      <p>
        Digite na caixa de busca acima os termos para a busca, por exemplo:
        “Pediatria Taguatinga” ou “Cardiologia Asa Norte”, você também pode
        pesquisar pelo nome do médico ou instituição, ou mesmo combinar os
        termos.
      </p>
    </div>
  );
};

export default BuscaProf;
