import React from 'react';
import { Route, Routes } from 'react-router-dom';
import VideosHome from './VideosHome';
import VideosList from './VideosList';
import VideoPage from './VideoPage';
import NovoVideo from './NovoVideo';
import EditarVideo from './VideoEdit';

const Videos = () => {
  return (
    <Routes>
      <Route path="/" element={<VideosHome />} />
      <Route path="/novo-video" element={<NovoVideo />} />
      <Route path="/:categoriaParam" element={<VideosList />} />
      <Route path="/:categoriaParam/:videoParam" element={<VideoPage />} />
      <Route path="/editar-video/:id" element={<EditarVideo />} />
    </Routes>
  );
};

export default Videos;
