import styles from './NavAssociado.module.css';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ReactComponent as Sair } from '../../Assets/sair.svg';
import { useUserContext } from '../../UserContext';
import { useEffect, useState } from 'react';
import { GET_CATEGORIAS } from '../../api';
import { useFileContext } from '../../useFileContext';

type Categoria = {
  descricao: string;
};

const NavAssociado = () => {
  const location = useLocation();
  const { userLogout, data, menuVisible, token } = useUserContext();
  const { pathname } = location;
  const { categorias, setCategorias } = useFileContext();
  const { categoria } = useFileContext();

  function handleSair() {
    userLogout();
  }

  useEffect(() => {
    async function getCategorias() {
      const { url, options } = GET_CATEGORIAS(token);
      const response = await fetch(url, options);
      const json = await response.json();
      if (!response.ok) throw new Error('Erro ao trazer categorias');
      setCategorias(json);
    }
    getCategorias();
  }, []);

  return (
    <nav className={`${styles.menu} ${menuVisible ? '' : styles.menuVisible}`}>
      <div className={styles.divTitulo}>
        <h1>
          Bem-vindo a{' '}
          {/* </h1>
        <h1> */}
          <span>AMAI</span>
        </h1>
      </div>
      <span className={styles.sep}></span>
      <div className={styles.divMenu}>
        <Link
          to="/area-associado"
          className={
            pathname === '/area-associado' ? styles.selected : styles.itemMenu
          }
        >
          Últimas circulares
        </Link>
        {!data?.pessoa_fisica && (
          <Link
            to="cadastrar-logo"
            className={
              pathname.includes('cadastrar-logo')
                ? styles.selected
                : styles.itemMenu
            }
          >
            Cadastrar logomarca
          </Link>
        )}
        <Link
          to="circulares"
          className={
            pathname.includes('circulares') ? styles.selected : styles.itemMenu
          }
        >
          Circulares
        </Link>
        <Link
          to="convenios/geral"
          className={
            pathname.includes('convenios') ? styles.selected : styles.itemMenu
          }
        >
          Convênios
        </Link>
        {pathname.includes('convenios') && (
          <div className={styles.menuConv}>
            <Link
              to="convenios/especialidades"
              className={
                pathname.includes('especialidades')
                  ? styles.selectedConv
                  : styles.itemConvenio
              }
            >
              Relação de especialidades
            </Link>
            <Link
              to="convenios/manuais"
              className={
                pathname.includes('manuais')
                  ? styles.selectedConv
                  : styles.itemConvenio
              }
            >
              Manuais {`(Atendimento / Preenchimento)`}
            </Link>
            <Link
              to="convenios/geral"
              className={
                pathname.includes('geral')
                  ? styles.selectedConv
                  : styles.itemConvenio
              }
            >
              Geral {`(Guias / Circulares / Informativos etc.)`}
            </Link>
            <Link
              to="convenios/atendimento-restrito"
              className={
                pathname.includes('atendimento-restrito')
                  ? styles.selectedConv
                  : styles.itemConvenio
              }
            >
              Atendimento restrito - Hosp. Anchieta{' '}
              {`(Guias / Circulares / Informativos etc.)`}
            </Link>
            <Link
              to="convenios/formulario-entrega"
              className={
                pathname.includes('formulario-entrega')
                  ? styles.selectedConv
                  : styles.itemConvenio
              }
            >
              Formulário de entrega de guias
            </Link>
            <Link
              to="convenios/tabelas"
              className={
                pathname.includes('tabelas')
                  ? styles.selectedConv
                  : styles.itemConvenio
              }
            >
              Tabelas Gerais
            </Link>
          </div>
        )}
        {!data?.pessoa_fisica && (
          <Link
            to="corpo-clinico"
            className={
              pathname.includes('corpo-clinico')
                ? styles.selected
                : styles.itemMenu
            }
          >
            Corpo clínico
          </Link>
        )}
        <Link
          to="dados-cadastrais"
          className={
            pathname.includes('dados-cadastrais')
              ? styles.selected
              : styles.itemMenu
          }
        >
          Dados cadastrais
        </Link>
        <Link
          to="extratos"
          className={
            pathname.includes('extratos') ? styles.selected : styles.itemMenu
          }
        >
          Extratos
        </Link>
        {/* <Link
          to="videos"
          className={
            pathname.includes('videos') ? styles.selected : styles.itemMenu
          }
        >
          Videos
        </Link> */}
        {pathname.includes('videos') && (
          <div className={styles.menuConv}>
            {categorias &&
              categorias.length > 0 &&
              categorias.map((item: Categoria, index) => (
                <Link
                  key={index}
                  to={`videos/${item.descricao}`}
                  className={
                    decodeURIComponent(pathname).includes(item.descricao)
                      ? styles.selectedConv
                      : styles.itemConvenio
                  }
                >
                  {item.descricao}
                </Link>
              ))}
          </div>
        )}
      </div>
      <div className={styles.sair} onClick={handleSair}>
        <Sair className={styles.svg} />
        <p>Sair</p>
      </div>
    </nav>
  );
};

export default NavAssociado;
