import { useState } from 'react';
import styles from './NovoVideo.module.css';
import TituloAssociado from '../Helper/TituloAssociado';
import Button from '../Forms/Button';
import { useUserContext } from '../../UserContext';
import { GET_CATEGORIAS, NOVA_CATEGORIA, POST_VIDEO } from '../../api';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { useFileContext } from '../../useFileContext';
import VideoForm from './VideoForm';
import Input from '../Forms/Input';

const NovoVideo = () => {
  const { formData } = useFileContext();
  const navigate = useNavigate();
  const [emailSent, setEmailSent] = useState(false);
  const [openAddCat, setOpenAddCat] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const { setLoading, tokenGer } = useUserContext();
  const { setCategorias } = useFileContext();
  const [initialValues, setInitialValues] = useState({
    url: '',
    titulo: '',
    descricao: '',
    categoria_id: undefined,
    convenio_id: undefined,
  });
  const [novaCat, setNovaCat] = useState('');

  async function getCategorias() {
    const { url, options } = GET_CATEGORIAS(tokenGer);
    const response = await fetch(url, options);
    const json = await response.json();
    if (!response.ok) throw new Error('Erro ao trazer categorias');
    setCategorias(json);
  }

  function fecharModal() {
    setEmailSent(false);
    navigate('/area-gerencial/videos');
  }

  function fecharModalCat() {
    setOpenAddCat(false);
  }

  async function fecharModalAddCat() {
    try {
      const { url, options } = NOVA_CATEGORIA(tokenGer, { descricao: novaCat });
      const response = await fetch(url, options);
      const json = await response.json();
      if (!response.ok) throw new Error(json.message);
    } catch (error) {
      console.log(error);
    } finally {
      setOpenAddCat(false);
      getCategorias();
    }
  }

  function onChangeNovaCat(e: React.ChangeEvent<HTMLInputElement>) {
    setNovaCat(e.target.value);
  }

  async function enviaVideo() {
    try {
      setLoading(true);
      const { url, options } = POST_VIDEO(tokenGer, formData);
      const response = await fetch(url, options);
      const { message } = await response.json();
      if (!response.ok) throw new Error(message);
      setMensagem(message);
      setEmailSent(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <section className={`${styles.containerMain} animeLeft`}>
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>{mensagem}</p>
        <Button descricao="fechar" classe="second" onClick={fecharModal} />
      </Modal>
      <Modal
        className={styles.modal}
        isOpen={openAddCat}
        onRequestClose={fecharModalCat}
        ariaHideApp={false}
      >
        <h2>Nova Categoria</h2>
        <Input
          label="Categoria"
          type="text"
          name="categoria"
          value={novaCat}
          onChange={onChangeNovaCat}
        />
        <Button
          descricao="salvar"
          classe="submit"
          onClick={fecharModalAddCat}
        />
      </Modal>
      <div className={styles.divTitulo}>
        <TituloAssociado titulo={'Novo Vídeo'} />
        <Button
          descricao="+ nova categoria"
          classe="third"
          onClick={() => {
            setNovaCat('');
            setOpenAddCat(true);
          }}
        />
      </div>
      <div className={styles.divForm}>
        <VideoForm initialValues={initialValues} />
        <div className={styles.botoes}>
          <Button descricao="salvar" classe="submit" onClick={enviaVideo} />
        </div>
      </div>
    </section>
  );
};

export default NovoVideo;
