import React, { useEffect, useMemo, useState } from 'react';
import styles from './PreClinicas.module.css';
import Head from '../Helper/Head';
import TituloAssociado from '../Helper/TituloAssociado';
import { Link } from 'react-router-dom';
import { useMedicoContext } from '../../useMedicoContext';
import { useUserContext } from '../../UserContext';
import { GET_ASSOCIADOS, GET_CLINICAS_PRE } from '../../api';

const PreClinicas = () => {
  const { setClinica, clinicasPre = [], setClinicasPre } = useMedicoContext();
  const { loading, setLoading, tokenGer } = useUserContext();
  const [pesquisar, setPesquisar] = useState('');
  const [pesquisando, setPesquisando] = useState(false);
  const [pesquisados, setPesquisados] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: '',
    direction: 'ascending',
  });

  function definirClinica(id: any): React.MouseEventHandler<HTMLAnchorElement> {
    return (event): void => {
      setClinica(clinicasPre.filter((item: any) => item.id_clinica === id));
    };
  }

  function handleChange({ target }: any) {
    setPesquisar(target.value.toUpperCase());
  }

  useEffect(() => {
    if (pesquisar && pesquisar.length > 2) {
      setPesquisando(true);
      setPesquisados(
        clinicasPre.filter((clinica: any) => {
          if (
            (clinica.nom_fantasia &&
              clinica.nom_fantasia.includes(pesquisar)) ||
            clinica.num_cnpj.includes(pesquisar) ||
            clinica.nom_clinica.includes(pesquisar)
          )
            return clinica;
        }),
      );
      // setPesquisados(pesquisados)
    } else {
      setPesquisando(false);
      setPesquisados([]);
    }
  }, [pesquisar]);

  useEffect(() => {
    async function buscarClinicas() {
      try {
        setLoading(true);
        const { url, options } = GET_CLINICAS_PRE(tokenGer);
        const response = await fetch(url, options);
        const data = await response.json();
        if (!response.ok) throw new Error(data.message);
        setClinicasPre(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
    setClinica({});
    buscarClinicas();
  }, []);

  const sortedClinicas = useMemo(() => {
    return [...clinicasPre].sort((a, b) => {
      if (sortConfig.key) {
        let aValue = a[sortConfig.key] || '';
        let bValue = b[sortConfig.key] || '';
        if (
          sortConfig.key === 'nom_fantasia' ||
          sortConfig.key === 'nom_clinica'
        ) {
          aValue = aValue.toUpperCase();
          bValue = bValue.toUpperCase();
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      }
      return 0;
    });
  }, [clinicasPre, sortConfig]);

  function requestSort(key: string) {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  }

  return (
    <section className={`${styles.containerMain} animeLeft`}>
      <Head title="Área Gerencial - PreClinicas" description="Área gerencial" />
      <TituloAssociado titulo="Pré cadastro - clínicas" />
      <input
        className={styles.procurar}
        type="text"
        placeholder="Pesquisar clínica"
        onChange={handleChange}
        value={pesquisar}
      />
      <div className={styles.divCorpoClinico}>
        <table className={styles.table}>
          <tr>
            <th onClick={() => requestSort('nom_fantasia')}>Nome</th>
            <th onClick={() => requestSort('num_cnpj')}>CNPJ</th>
            <th onClick={() => requestSort('ind_status')}>Status</th>
          </tr>

          {pesquisando ? (
            pesquisados &&
            pesquisados.map((item: any, index: any) => (
              <>
                <Link
                  key={index}
                  to={item.num_cnpj}
                  onClick={definirClinica(item.id_clinica)}
                >
                  <tr>
                    <td className={styles.td}>
                      {item.nom_fantasia ? item.nom_fantasia : item.nom_clinica}
                    </td>
                    <td className={styles.td}>{item.num_cnpj}</td>
                    <td className={styles.td}>{item.ind_status}</td>
                  </tr>
                </Link>
                <span className={styles.sep}></span>
              </>
            ))
          ) : sortedClinicas && sortedClinicas.length !== 0 ? (
            sortedClinicas.map((item: any, index: any) => (
              <>
                <Link
                  key={index}
                  to={item.num_cnpj}
                  onClick={definirClinica(item.id_clinica)}
                >
                  <tr>
                    <td className={styles.td}>
                      {item.nom_fantasia ? item.nom_fantasia : item.nom_clinica}
                    </td>
                    <td className={styles.td}>{item.num_cnpj}</td>
                    <td className={styles.td}>{item.ind_status}</td>
                  </tr>
                </Link>
                <span className={styles.sep}></span>
              </>
            ))
          ) : (
            <tr>
              <td className={`${styles.nenhum} spanAll`}>
                Nenhuma clínica aguardando aprovação de cadastro
              </td>
            </tr>
          )}
        </table>
      </div>
    </section>
  );
};

export default PreClinicas;
