import React, { useEffect, useState } from 'react';
import styles from './Medico.module.css';
import TituloAssociado from '../Helper/TituloAssociado';
import { useMedicoContext } from '../../useMedicoContext';
import ClinicaForm from './ClinicaForm';
import {
  ALTERA_CADASTRO_MEDICO,
  APROVA_CADASTRO,
  APROVA_CADASTRO_MEDICO,
  DELETE_MEDICO,
  GET_DOCS_CLINICA,
  GET_DOCS_MEDICO,
} from '../../api';
import { useUserContext } from '../../UserContext';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../Forms/Button';
import MedicoForm from '../AreaDoAssociado/MedicoForm';
import Modal from 'react-modal';

const Medico = () => {
  const { tokenGer, loading, setLoading } = useUserContext();
  const { medico, formData, setFormData } = useMedicoContext();
  const [docs, setDocs] = useState<any>([]);
  const tipos = [
    'Documento de identidade',
    'CPF',
    'Carteira do conselho',
    'Diploma',
    'Titulo de especialização ou Residência médica registrado no conselho',
    'Currículo',
    'Quitação com conselho',
    'Carta de inclusão',
  ];
  const dateOnly = medico[0].dat_nascimento.split('T')[0];
  const [ano, mes, dia] = dateOnly.split('-');
  const dataNasc = dia + '/' + mes + '/' + ano;
  const navigate = useNavigate();
  const [emailSent, setEmailSent] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    nom_medico: medico[0].nom_medico,
    dat_nascimento: dataNasc,
    num_cpf: medico[0].num_cpf,
    sgl_conselho: medico[0].sgl_conselho,
    num_crm: medico[0].num_crm,
    especialidades: medico[0].especialidades,
    num_identidade: medico[0].num_identidade,
    nom_orgao_expedidor: medico[0].nom_orgao_expedidor,
    naturalidade: medico[0].naturalidade,
    des_uf: medico[0].des_uf,
    des_sexo_medico: medico[0].des_sexo_medico,
    des_endereco: medico[0].des_endereco,
    des_estado_civil: medico[0].des_estado_civil,
    cidade: medico[0].cidade,
    num_cep: medico[0].num_cep,
    tel_fixo: medico[0].tel_fixo,
    tel_celular: medico[0].tel_celular,
    des_email: medico[0].des_email,
    whatsapp: medico[0].whatsapp,
    instagram: medico[0].instagram,
    resp_cadastro: medico[0].resp_cadastro,
    tel_resp_cadastro: medico[0].tel_resp_cadastro,
    uf_conselho: medico[0].uf_conselho,
    uf_endereco: medico[0].uf_endereco,
    docs: medico[0].docs,
  });

  function fecharModal() {
    setOpenModal(false);
    setEmailSent(false);
    navigate(-1);
  }
  async function aprovaCadastro() {
    try {
      setLoading(true);
      const { url, options } = ALTERA_CADASTRO_MEDICO(tokenGer, {
        id_clinica: medico[0].id_clinica,
        id_medico: medico[0].id_medico,
        data: formData,
      });
      const response = await fetch(url, options);
      const { message } = await response.json();
      if (!response.ok) throw new Error(message);
      setMensagem(message);
      setEmailSent(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    async function getDocsMedico() {
      try {
        const { url, options } = GET_DOCS_MEDICO(tokenGer, {
          id_medico: medico[0].id_medico,
        });
        const response = await fetch(url, options);
        const json = await response.json();
        if (!response.ok)
          throw new Error('Erro ao tentar trazer os arquivos da clínica');
        setDocs(json);
      } catch (error) {
        console.log(error);
      }
    }
    getDocsMedico();
  }, []);

  return (
    <section className={`${styles.containerMain} animeLeft`}>
      <TituloAssociado
        titulo={medico[0].nom_medico}
        crm={medico[0].sgl_conselho + ' - ' + medico[0].num_crm}
        especialidade={medico[0].especialidades}
      />
      <Modal
        className={styles.modal}
        isOpen={emailSent}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>{mensagem}</p>
        <Button descricao="fechar" classe="second" onClick={fecharModal} />
      </Modal>
      <div className={styles.divForm}>
        <MedicoForm initialValues={initialValues} />
        <div className={styles.divDocumentos}>
          <h2>Documentos</h2>
          {docs && docs.length === 0 ? (
            <p>Nenhum documento encontrado para esse médico</p>
          ) : (
            <div className={styles.divLinks}>
              {docs.map((item: any, index: any) => (
                <Link key={index} to={item.url} target="_blank">
                  {tipos[item.tipo - 1]}
                </Link>
              ))}
            </div>
          )}
        </div>
        <div className={styles.buttons}>
          <Button
            descricao={loading ? 'Salvando...' : 'salvar'}
            classe="submit"
            disabled={loading}
            onClick={aprovaCadastro}
          />
        </div>
      </div>
    </section>
  );
};

export default Medico;
