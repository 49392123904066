import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from './ConvenioGer.module.css';
import { useAssociadoContext } from '../../useAssociadoContext';
import Head from '../Helper/Head';
import TituloAssociado from '../Helper/TituloAssociado';
import Button from '../Forms/Button';

const ConvenioGer = () => {
  const { id } = useParams();
  const { convenios, convenio, setConvenio } = useAssociadoContext();

  useEffect(() => {
    function definirConvenio() {
      const convenioAtual = convenios?.filter((item: any) => item.id == id);
      setConvenio(convenioAtual);
    }
    definirConvenio();
  }, []);

  return (
    <section className={`${styles.containerPrincipal} animeLeft`}>
      <Head
        title="Área do Associado - Convenios Geral"
        description="Área do associado"
      />
      {convenio && (
        <div className={styles.divTitulo}>
          <TituloAssociado
            titulo={convenio[0]?.nome}
            usuario={convenio[0].usuario ? convenio[0].usuario : ''}
            senha={convenio[0].senha ? convenio[0].senha : ''}
            tipoAut={convenio[0].tipo_aut ? convenio[0].tipo_aut : ''}
            dataCred={
              convenio[0].data_alt_credencial
                ? convenio[0].data_alt_credencial
                : ''
            }
            horaCred={
              convenio[0].hora_alt_credencial
                ? convenio[0].hora_alt_credencial
                : ''
            }
          />
          <Link to="alterar-convenio">
            <Button descricao="alterar convênio" classe="third" />
          </Link>
        </div>
      )}
      <div className={styles.divConvenio}>
        {convenio && convenio[0].url_aut ? (
          <a
            href={
              convenio[0].url_aut.includes('http')
                ? convenio[0].url_aut
                : 'https://' + convenio[0].url_aut
            }
            target="_blank"
          >
            Link para obter autorização do convênio
          </a>
        ) : convenio && convenio[0].instrucao_aut ? (
          <p className={styles.alerta}>{convenio[0].instrucao_aut}</p>
        ) : (
          <p className={styles.alerta}>
            Paciente leva a guia autorizada pelo convênio.
          </p>
        )}
        {convenio && (
          <Link to="circulares">circulares - {convenio[0].nome}</Link>
        )}
        {convenio && <Link to="informativos">informativos</Link>}
        {convenio && <Link to="guias">guias</Link>}
        {convenio && <Link to="videos">videos</Link>}
      </div>
    </section>
  );
};

export default ConvenioGer;
