import {
  Link,
  Params,
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from 'react-router-dom';
import styles from './VideosList.module.css';
import VideoFrameList from './VideoFrameList';
import VideoTitle from './VideoTitle';
import VideoDescription from './VideoDescription';
import { useEffect, useState } from 'react';
import { DELETE_VIDEO, GET_VIDEOS } from '../../api';
import { useUserContext } from '../../UserContext';
import { useFileContext } from '../../useFileContext';
import TituloAssociado from '../Helper/TituloAssociado';
import { ReactComponent as Delete } from '../../Assets/delete.svg';
import { ReactComponent as Editar } from '../../Assets/editar.svg';
import Modal from 'react-modal';
import path from 'path';
import Button from '../Forms/Button';

type Video = {
  id: number;
  url: string;
  descricao: string;
  titulo: string;
};

const VideosList = () => {
  const { token, tokenGer, loading, setLoading } = useUserContext();
  const { categoriaParam }: Readonly<Params<string>> = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { categorias, categoria, setCategoria, videos, setVideos, setVideo } =
    useFileContext();
  const [pesquisar, setPesquisar] = useState('');
  const [pesquisados, setPesquisados] = useState<Partial<Video>[]>();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [idVideo, setIdVideo] = useState(0);
  const [mensagem, setMensagem] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setPesquisar(query);
    const handleSearch = (query: string) => {
      const filtered: Partial<Video>[] | undefined = videos?.filter((video) => {
        const lowerCaseQuery = query.toLowerCase();
        return Object.values(video).some(
          (valor) =>
            typeof valor === 'string' &&
            valor.toLocaleLowerCase().includes(lowerCaseQuery),
        );
      });

      setPesquisados(filtered);
    };

    if (query.length > 2) {
      handleSearch(query);
    } else {
      handleSearch('');
    }
  };
  useEffect(() => {
    async function definirCategoria() {
      setCategoria([]);
      const item = categorias?.find(
        (item) =>
          item.descricao === decodeURIComponent(categoriaParam as string),
      );
      setCategoria(item ? [item] : []);
    }

    definirCategoria();
  }, [pathname, categoriaParam, categorias, setCategoria]);

  async function getVideos() {
    if (categoria && categoria.length > 0) {
      const { url, options } = GET_VIDEOS(token ? token : tokenGer, {
        categoria: categoria[0].id,
      });
      const response = await fetch(url, options);
      const json = await response.json();
      if (!response.ok) throw new Error('Erro ao trazer vídeos');
      setVideos(json);
      setPesquisados(json);
    }
  }

  useEffect(() => {
    getVideos();
  }, [categoria, token, tokenGer, setVideos]);

  async function deleteVideo() {
    try {
      setLoading(true);
      setModalOpen(false);
      const { url, options } = DELETE_VIDEO(tokenGer, { id: idVideo });
      const response = await fetch(url, options);
      const { message } = await response.json();
      setMensagem(await message);
      if (!response.ok) throw new Error(message);
      getVideos();
      setModalOpen2(true);
    } catch (error) {
      console.log('Erro no deleteVideo: ' + error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  function fecharModal() {
    setModalOpen(false);
    setModalOpen2(false);
  }

  function abrirModal(id: any) {
    setModalOpen(true);
    setIdVideo(id);
    setMensagem(
      'Confirma a EXCLUSÃO do video? Essa operação não poderá ser desfeita!',
    );
  }

  function editarVideo(id: any) {
    const videoFiltrado = videos?.filter((video) => video.id === Number(id));
    if (videoFiltrado) setVideo(videoFiltrado[0]);
    navigate('/area-gerencial/videos/editar-video/' + id);
  }

  return (
    <section className={`${styles.containerMain} animeLeft`}>
      <Modal
        className={styles.modal}
        isOpen={modalOpen}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>{mensagem}</p>
        <div className={styles.botoes}>
          <Button descricao="excluir" classe="submit" onClick={deleteVideo} />
          <Button descricao="cancelar" classe="cancel" onClick={fecharModal} />
        </div>
      </Modal>
      <Modal
        className={styles.modal}
        isOpen={modalOpen2}
        onRequestClose={fecharModal}
        ariaHideApp={false}
      >
        <p>{mensagem}</p>
        <Button descricao="fechar" classe="main" onClick={fecharModal} />
      </Modal>
      <TituloAssociado
        titulo={categoria && categoria.length > 0 ? categoria[0].descricao : ''}
      />
      <input
        className={styles.procurar}
        type="text"
        placeholder="Busque o vídeo que deseja..."
        onChange={handleChange}
        value={pesquisar}
      />
      {pesquisados && pesquisados.length > 0 ? (
        pesquisados.map((video: Partial<Video>, index) => (
          <div className={styles.videoFuncs}>
            <Link to={`${video.titulo}`}>
              <div key={index} className={styles.videoContainer}>
                <VideoFrameList
                  url={`https://www.youtube.com/embed/${video.url}` as string}
                />
                <div className={styles.dadosContainer}>
                  <VideoTitle title={video.titulo as string} />
                  <VideoDescription description={video.descricao as string} />
                </div>
              </div>
            </Link>
            {pathname.includes('gerencial') && (
              <div className={styles.videoButtons}>
                <Editar
                  className={styles.svgEditar}
                  onClick={() => editarVideo(video.id)}
                  title="Editar"
                />
                <Delete
                  className={styles.svg}
                  onClick={() => abrirModal(video.id)}
                  title="Excluir"
                />
              </div>
            )}
          </div>
        ))
      ) : (
        <p>Essa categoria ainda não tem vídeos.</p>
      )}
    </section>
  );
};

export default VideosList;
