import { useState } from 'react';
import styles from './VideoFrameList.module.css';

const VideoFrameList = ({ url }: { url: string }) => {
  const [isHover, setIsHover] = useState(false);

  return (
    <iframe
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={styles.video}
      src={`${url}${isHover ? '?autoplay=1&muted=1' : ''}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="YouTube video player"
    ></iframe>
  );
};

export default VideoFrameList;
