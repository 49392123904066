import { useEffect, useState } from 'react';
import { useFileContext, Video } from '../../useFileContext';
import styles from './VideoPageConv.module.css';
import { useParams } from 'react-router-dom';

const VideoPageConv = () => {
  const { videos, setCategoria } = useFileContext();
  const [video, setVideo] = useState<Video | undefined>();
  const { videoParam } = useParams();
  const definirVideo = () => {
    const videoFiltrado = videos?.filter(
      (video) =>
        video.titulo === decodeURIComponent(videoParam ? videoParam : ''),
    );
    if (videoFiltrado) setVideo(videoFiltrado[0]);
  };

  useEffect(() => {
    definirVideo();
  }, []);

  return (
    <section className={styles.containerMain}>
      <iframe
        className={styles.video}
        src={video?.url}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={video?.titulo}
      ></iframe>
      <div className={styles.containerDados}>
        <h1>{video?.titulo?.toUpperCase()}</h1>
        <p>{video?.descricao}</p>
      </div>
    </section>
  );
};

export default VideoPageConv;
