import React, { useEffect, useState } from 'react';
import styles from './VideoForm.module.css';
import Input from '../Forms/Input';
import { useFileContext, VideoFormTypes } from '../../useFileContext';
import { GET_CONVENIOS_GERENCIAL } from '../../api';
import { useAssociadoContext } from '../../useAssociadoContext';
import { formatDate } from 'date-fns';

interface InitialValuesTypes {
  initialValues: VideoFormTypes;
}
const VideoForm = ({ initialValues }: InitialValuesTypes) => {
  const { categorias, formData, setFormData, setVideo } = useFileContext();
  const { convenios, setConvenios } = useAssociadoContext();
  const [convAtual, setConvAtual] = useState<any>();
  const [catAtual, setCatAtual] = useState<any>();
  useEffect(() => {
    setFormData({});
    async function getConvenios() {
      const { url, options } = GET_CONVENIOS_GERENCIAL();
      const response = await fetch(url, options);
      const data = await response.json();
      if (!response.ok) throw new Error('Erro no get convenios.');
      setConvenios(data);
    }
    getConvenios();
  }, []);

  useEffect(() => {
    setFormData(initialValues);
  }, [initialValues]);

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { name, value } = event.target;
    if (formData) setFormData({ ...formData, [name]: value });
  };

  return (
    <div className={styles.form}>
      <Input
        label="URL do vídeo"
        type="text"
        name="url"
        onChange={handleInputChange}
        value={formData?.url}
        maxLength={300}
        classe="spanAll"
      />
      <Input
        label="Título"
        type="text"
        name="titulo"
        onChange={handleInputChange}
        value={formData?.titulo}
        classe="spanAll"
      />
      <div className={`${styles.divTipo} col6`}>
        <label htmlFor="categorias">Categoria</label>
        <select
          name="categoria_id"
          id="categorias"
          onChange={handleInputChange}
          value={formData?.categoria_id}
        >
          <option value={''}>Selecione uma categoria...</option>
          {categorias?.map((categoria) => (
            <option key={categoria.id} value={categoria.id}>
              {categoria.descricao}
            </option>
          ))}
        </select>
      </div>
      <div className={`${styles.divTipo} col5`}>
        <label htmlFor="convenios">Convenio</label>
        <select
          name="convenio_id"
          id="convenios"
          onChange={handleInputChange}
          value={formData?.convenio_id}
        >
          <option defaultValue={'Selecione uma categoria...'} value="">
            Selecione um convenio...
          </option>
          {convenios?.map((convenio: any) => (
            <option key={convenio.id} value={convenio.id}>
              {convenio.nome}
            </option>
          ))}
        </select>
      </div>
      <div className={`${styles.mensagem} spanAll`}>
        <label htmlFor="descricao">Descrição</label>
        <textarea
          id="descricao"
          name="descricao"
          placeholder="Digite a descrição do vídeo..."
          onChange={handleInputChange}
          value={formData?.descricao}
        />
      </div>
    </div>
  );
};

export default VideoForm;
