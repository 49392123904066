import { useEffect } from 'react';
import styles from './AreaAssociado.module.css';
import { useUserContext } from '../../UserContext';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomeAssociado from './HomeAssociado';
import NavAssociado from '../NavAssociado/NavAssociado';
import CircularesAss from './CircularesAss';
import CadastrarLogo from './CadastrarLogo';
import Extratos from './Extratos';
import AtendimentoRestrito from './AtendimentoRestrito';
import Convenio from './Convenio';
import ConveniosGeral from './ConveniosGeral';
import Especialidades from './Especialidades';
import Manuais from './Manuais';
import FormularioGuias from './FormularioGuias';
import Tabelas from './Tabelas';
import CorpoClinico from './CorpoClinico';
import Medico from './Medico';
import NovoMedico from './NovoMedico';
import ManuaisLinks from './ManuaisLinks';
import CircularesConv from './CircularesConv';
import InfosConv from './InfosConv';
import Especialidade from './Especialidade';
import GuiasConv from './GuiasConv';
import { ReactComponent as Arrow } from '../../Assets/arrow.svg';
import Videos from '../Videos/Videos';
import DadosCadastrais from './DadosCadastrais';

const AreaAssociado = () => {
  const { menuVisible, setMenuVisible } = useUserContext();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    setMenuVisible(false);
  }, [pathname, setMenuVisible]);

  function toggleMenu() {
    setMenuVisible(!menuVisible);
  }
  return (
    <>
      <section className={styles.main}>
        <NavAssociado />
        <div className={styles.toggleButton} onClick={toggleMenu}>
          <Arrow className={menuVisible ? styles.svg : styles.svgReverse} />
        </div>
        <Routes>
          <Route path="/" element={<HomeAssociado />} />
          <Route path="/circulares" element={<CircularesAss />} />
          <Route path="/cadastrar-logo" element={<CadastrarLogo />} />
          <Route path="/extratos" element={<Extratos />} />
          <Route
            path="/convenios/atendimento-restrito"
            element={<AtendimentoRestrito />}
          />
          <Route path="/convenios/:id" element={<Convenio />} />
          <Route
            path="/convenios/:id/circulares"
            element={<CircularesConv />}
          />
          <Route path="/convenios/:id/informativos" element={<InfosConv />} />
          <Route path="/convenios/:id/guias" element={<GuiasConv />} />
          <Route path="/convenios/geral" element={<ConveniosGeral />} />
          <Route
            path="/convenios/especialidades"
            element={<Especialidades />}
          />
          <Route
            path="/convenios/especialidades/:id"
            element={<Especialidade />}
          />
          <Route path="/convenios/manuais" element={<Manuais />} />
          <Route path="/convenios/manuais/:param" element={<ManuaisLinks />} />
          <Route
            path="/convenios/formulario-entrega"
            element={<FormularioGuias />}
          />
          <Route path="/convenios/tabelas" element={<Tabelas />} />
          <Route path="/corpo-clinico" element={<CorpoClinico />} />
          <Route path="/corpo-clinico/:id" element={<Medico />} />
          <Route path="/corpo-clinico/novo-medico" element={<NovoMedico />} />
          <Route path="/dados-cadastrais" element={<DadosCadastrais />} />
          <Route path="/videos/*" element={<Videos />} />
        </Routes>
      </section>
    </>
  );
};

export default AreaAssociado;
