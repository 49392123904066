import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import styles from './Numeros.module.css';
import { useInView } from 'react-intersection-observer';

const Numeros = () => {
  const [contador, setContador] = useState(0);
  const [contador2, setContador2] = useState(0);
  const [contador3, setContador3] = useState(0);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  function acrescentarNums<T>(
    numAlvo: number,
    nome: Dispatch<SetStateAction<T>>,
  ) {
    if (inView) {
      // const intervalo = Math.max(5000 / numAlvo, 0.001);
      const intervalId = setInterval(() => {
        nome((prevContador: T) => {
          if (typeof prevContador === 'number') {
            return Math.min(prevContador + numAlvo / 100, numAlvo) as T;
          } else {
            clearInterval(intervalId);
            return prevContador;
          }
        });
      }, 20);

      return () => clearInterval(intervalId);
    }
  }

  useEffect(() => {
    if (inView) {
      acrescentarNums(2000, setContador);
      acrescentarNums(30, setContador2);
      acrescentarNums(45, setContador3);
    }
  }, [inView]);

  return (
    <section
      className={`${styles.containerPrincipal} ${inView ? 'animeLeft' : ''}`}
      ref={ref}
    >
      <div className={styles.divPrincipal}>
        <h2>Grandeza da AMAI</h2>
        <div className={styles.divNumeros}>
          <div className={styles.containerTextos}>
            <div className={styles.divTextos}>
              <p>contamos com</p>
              <h1>+ {Math.round(contador)}</h1>
              <p>associados</p>
            </div>
          </div>
          <div className={styles.containerTextos}>
            <div className={styles.divTextos}>
              <p>temos</p>
              <h1>+ {Math.round(contador2)}</h1>
              <p>anos de história</p>
            </div>
          </div>
          <div className={styles.containerTextos}>
            <div className={styles.divTextos}>
              <p>Cadastrados com</p>
              <h1>+ {Math.round(contador3)}</h1>
              <p>Convênios</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Numeros;
